<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  labelKeyAdd=""
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableSailorPassportReport',
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$t('createDate') },
        { value: 'sailor.id', text: this.$t('sailorId') },
        { value: 'number_document', text: this.$t('number') },
        { value: 'port', text: this.$t('port') },
        { value: 'country', text: this.$t('country') },
        { value: 'sailorFullName', text: this.$t('fullName') },
        { value: 'date_start', text: this.$t('dateIssue') },
        { value: 'date_end', text: this.$t('dateTermination') },
        { value: 'status_document', text: this.$t('status') },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ name: 'passports-sailors-info', params: { id: item.sailor.id, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listSailorPassportReport,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListSailorPassportReport']),
    async getData (params) {
      this.getListSailorPassportReport({ ...this.$route.params, ...params })
    }

  }
}
</script>
